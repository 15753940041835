import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AutoComplete, Input, Radio } from 'antd';
import { useQuery } from '../../../helpers';
import { FilterSection } from '../styles';
import {
  NAME_SEARCH,
  MUNICIPALITY_SEARCH,
  MUNICIPALITY_SEARCH_HOLDER,
} from '../../../constants';
import kuntaData from './kunnat.json';

/* eslint-disable react/prop-types */
function Search({
  setParentSearchValue,
  defaultSearchValue,
  defaultKuntaValue,
  setParentKuntaValue,
}) {
  const dataSource = useSelector((state) => state.candidates);

  const [searchValue, setSearchValue] = useState(defaultSearchValue);
  const [kuntaValue, setKuntaValue] = useState(defaultKuntaValue);
  const [searchType, setSearchType] = useState('kunta');

  const query = useQuery();
  const { print } = query;

  useEffect(() => {
    setParentSearchValue(searchValue);
    setParentKuntaValue(kuntaValue);
  }, [searchValue, setParentSearchValue, kuntaValue, setParentKuntaValue]);

  const onChange = (e) => {
    setSearchType(e.target.value);
    setSearchValue(null);
    setKuntaValue(null);
  };

  if (!dataSource) {
    return null;
  }

  const listat = kuntaData.map((ob, idx) => ({
    value: ob,
    label: ob,
    key: idx,
  }));

  const options = dataSource.map((ob, idx) => ({
    value: ob.title.rendered,
    label: ob.title.rendered,
    key: idx,
  }));

  return (
    <>
      {!print && (
        <FilterSection>
          <Radio.Group onChange={onChange} value={searchType}>
            <Radio value="kunta">{MUNICIPALITY_SEARCH}</Radio>
            <Radio value="nimi">{NAME_SEARCH}</Radio>
          </Radio.Group>
        </FilterSection>
      )}
      {searchType === 'nimi' ? (
        <AutoComplete
          style={{
            width: '100%',
          }}
          value={searchValue}
          onChange={(e, value) => {
            setSearchValue(value.value);
          }}
          defaultValue={
            options.find((o) => o.id && o.id === searchValue) || undefined
          }
          options={options}
          filterOption={(inputValue, option) =>
            option.label
              .toUpperCase()
              .indexOf(inputValue.toString().toUpperCase()) !== -1
          }
        >
          <Input.Search size="large" placeholder="Search" />
        </AutoComplete>
      ) : (
        <AutoComplete
          style={{
            width: '100%',
          }}
          value={kuntaValue}
          onChange={(event, value) => {
            setKuntaValue(value.value);
          }}
          options={listat}
          filterOption={(inputValue, option) =>
            option.label
              .toUpperCase()
              .indexOf(inputValue.toString().toUpperCase()) !== -1
          }
        >
          <Input.Search size="large" placeholder={MUNICIPALITY_SEARCH_HOLDER} />
        </AutoComplete>
      )}
    </>
  );
}

export default Search;

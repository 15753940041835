import { Col, Select, Row } from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@namia/typography';
import { setFilters } from './actions';
import { useQuery } from '../../helpers';
import {
  ROW_GUTTER,
  PARTY_FILTER,
  UNION_FILTER,
  CHOOSE_UNION,
  CHOOSE_PARTY,
  CURRENT_LANG,
  ALL,
} from '../../constants';
import Search from './Search';
import { FilterSection } from './styles';

import liitot from './liitot.json';
import puolueet from './puolueet.json';

const { Option } = Select;

/* eslint no-nested-ternary: */
const Filters = () => {
  const dispatch = useDispatch();

  const { search, kunta, puolue, liitto } = useSelector(
    (state) => state.filters
  );
  // Use URL query to set initial values.
  const query = useQuery();
  const {
    search: searchParam,
    kunta: kuntaParam,
    puolue: puolueParam,
    liitto: liittoParam,
    print,
  } = query;

  const defaultPuolue = puolueParam || puolue;

  const defaultLiitto = liittoParam || liitto;
  const defaultSearch = searchParam || search;
  const defaultKunta = kuntaParam || kunta;

  const [puolueValue, setPuolueValue] = useState(defaultPuolue);
  const [liittoValue, setLiittoValue] = useState(defaultLiitto);
  const [searchValue, setSearchValue] = useState(defaultSearch);
  const [kuntaValue, setKuntaValue] = useState(defaultKunta);

  const resetPaging = useCallback(() => {
    dispatch(
      setFilters({
        maxValue: 9,
        minValue: 0,
        currentPage: 1,
      })
    );
  }, [dispatch]);

  // Handle Filters change.
  useEffect(() => {
    // Set filters to store.
    dispatch(
      setFilters({
        puolue: puolueValue,
        liitto: liittoValue,
        search: searchValue,
        kunta: kuntaValue,
      })
    );
  }, [puolueValue, searchValue, kuntaValue, liittoValue, dispatch]);

  return (
    <Row gutter={[ROW_GUTTER, ROW_GUTTER]}>
      <Col xs={22} md={7}>
        <Search
          setParentSearchValue={(value) => {
            if (value && value !== searchValue) {
              resetPaging();
            }
            setSearchValue(value);
          }}
          defaultSearchValue={searchValue}
          defaultKuntaValue={kuntaValue}
          setParentKuntaValue={(value) => {
            if (value && value !== kuntaValue) {
              resetPaging();
            }
            setKuntaValue(value);
          }}
        />
      </Col>
      <Col xs={22} md={7}>
        {!print && (
          <FilterSection>
            <Typography type="basic" elementType="p" size="1" uppercase>
              {PARTY_FILTER}
            </Typography>
          </FilterSection>
        )}
        <Select
          size="large"
          defaultValue={puolueValue || CHOOSE_PARTY}
          onChange={(value) => {
            if (value && value !== puolueValue) {
              resetPaging();
            }
            setPuolueValue(value);
          }}
          style={{ width: '100%' }}
        >
          <Option value="Kaikki">{ALL}</Option>
          {Object.keys(puolueet).map((p) => (
            <Option value={p}>
              {CURRENT_LANG === 'fi'
                ? puolueet[p].fi
                : CURRENT_LANG === 'sv'
                ? puolueet[p].sv
                : puolueet[p].en}
            </Option>
          ))}
        </Select>
      </Col>

      <Col xs={22} md={7}>
        {!print && (
          <FilterSection>
            <Typography type="basic" elementType="p" size="1" uppercase>
              {UNION_FILTER}
            </Typography>
          </FilterSection>
        )}
        <Select
          size="large"
          defaultValue={liittoValue || CHOOSE_UNION}
          onChange={(value) => {
            if (value && value !== liittoValue) {
              resetPaging();
            }
            setLiittoValue(value);
          }}
          style={{ width: '100%' }}
        >
          <Option value="Kaikki">{ALL}</Option>

          {Object.keys(liitot).map((l) => (
            <Option value={l}>
              {CURRENT_LANG === 'fi'
                ? liitot[l].fi
                : CURRENT_LANG === 'sv'
                ? liitot[l].sv
                : liitot[l].en}
            </Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};

export default Filters;

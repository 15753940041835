// eslint-disable-next-line import/prefer-default-export
export const ROW_GUTTER = { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 };
export const BOX_SHADOW =
  '0px 12px 17px rgba(2, 3, 3, 0.03), 0px 5px 22px rgba(2, 3, 3, 0.02), 0px 7px 8px rgba(2, 3, 3, 0.04)';

export const CURRENT_LANG = window?.electionApp?.currentLang || '';
export const NAME_SEARCH = window?.electionApp?.pllName || '';
export const MUNICIPALITY_SEARCH = window?.electionApp?.pllMunicipality || '';
export const MUNICIPALITY_SEARCH_HOLDER =
  window?.electionApp?.pllMunicipalityHolder || '';
export const PARTY_FILTER = window?.electionApp?.pllPartyFilter || '';
export const CHOOSE_PARTY = window?.electionApp?.pllChooseParty || '';
export const ALL = window?.electionApp?.pllAll || '';
export const UNION_FILTER = window?.electionApp?.pllUnionFilter || '';
export const CHOOSE_UNION = window?.electionApp?.pllChooseUnion || '';
export const SHARE_TEXT = window?.electionApp?.pllShare || '';
export const SHARE_LINK = window?.electionApp?.pllShareLink || '';
export const INTRO_TEXT = window?.electionApp?.pllIntroduction || '';
export const INTRO_TEXT_SV = window?.electionApp?.pllIntroductionSv || '';
export const INTRO_TEXT_EN = window?.electionApp?.pllIntroductionEn || '';
export const COPY_LINK = window?.electionApp?.pllCopyLink || '';
export const LINK_COPIED = window?.electionApp?.pllLinkCopied || '';
export const THANKS_TEXT = window?.electionApp?.pllThanks || '';
export const PRINT_FILENAME = window?.electionApp?.pllPrintFileName || '';
export const WP_ENV = window?.electionApp?.wpEnv || '';
export const NO_RESULTS_TEXT = window?.electionApp?.pllNoResults || '';
export const GO_BACK_TEXT = window?.electionApp?.pllGoBack || '';

// eslint-disable-next-line import/prefer-default-export
export const filterCandidate = (candidate, filters) => {
  const { puolue, liitto, search, kunta } = filters;

  const pv = puolue === 'Kaikki' ? null : puolue;
  const lv = liitto === 'Kaikki' ? null : liitto;

  const result = [candidate]
    .filter(({ puolue: candidatePuolue }) =>
      pv ? pv === candidatePuolue : true
    )
    .filter(({ ammattiliitto }) => (lv ? lv === ammattiliitto : true))
    .filter(({ title }) =>
      search
        ? search.length > 2 &&
          title.rendered.toLowerCase().includes(search.toLowerCase())
        : true
    )
    .filter(({ kunta: candidateKunta }) =>
      kunta ? kunta.includes(candidateKunta) : true
    );
  return result.length > 0;
};

export const sortCandidate = (a, b) => {
  if (a.title.rendered < b.title.rendered) {
    return -1;
  }
  if (a.title.rendered > b.title.rendered) {
    return 1;
  }
  return 0;
};
